<template>
  <a-range-picker
      :key="id"
      ref="rangeDate"
      :defaultValue="defaultValue"
      :ranges="ranges"
      @change="onChange" style="width: 100%"></a-range-picker>
</template>

<script>
import moment from 'moment'
export default {
  name: 'RangeDate',
  props:['defaultStart'],
  data () {
    return {
      id: +new Date(),
      ranges: {
        '今天': [moment(), moment()],
        '昨天': [moment().add(-1,'day'),moment().add(-1,'day')],
        '本周': [moment().startOf('week'), moment().endOf('week')],
        '本月': [moment().startOf('month'), moment().endOf('month')],
        '上月': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
        '近半年': [moment().subtract(6,'months'), moment()],
        '近一年': [moment().subtract(1,'years'), moment()],
      }
    }
  },
  computed:{
    defaultValue(){
      if(!this.defaultStart) return [];
      if(this.defaultStart=='today') return [moment()]
      return [];
    }
  },
  methods: {
    onChange (date, dateString) {
      this.$emit('change', dateString)
    },
    reset () {
      this.id = +new Date()
    }
  }
}
</script>
